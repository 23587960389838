@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Header{@include sec-default();position:relative;z-index:999;text-align:center;
  .hdrWrap{width:95%;max-width:1300px;@include center-block();padding-top:15px;background:transparent;position:relative;z-index:999;}

  .left,.right{min-width:400px;}
  .left{margin-left:-1%;}
  .right{margin-right:-1%;}
  .navBarCustom{@include center-block();margin:0 auto 30px;padding:0;}
  .logoBlk{display:none;}
  .logoLink{max-width:420px;@include center-block();}

  .navItem{display:inline-block;padding:0;vertical-align:top;position:relative;margin:0px 15px;
    &:first-child{margin-left:0;}
    &, a{color:$gray;height:auto;min-height:auto;font-family:$body;text-transform:none;font-size:18px;}
    img{@include center-block();}

    a{padding:0;}
    &:hover > a,a:hover{color:$color2;opacity:.7;}
  }

  .custDrop{background-color:$dark3;text-align:center;padding:0;
    li{display:inline-block;margin:10px 15px;padding:2px 0 3px;}
    a{font-size:17px;color:$white;}
    li:hover a, a:hover{color:$white;opacity:.7;}
  }

  p{font-weight:400;color:$gray;height:auto;min-height:auto;font-family:$body;text-transform:none;font-size:18px;
    a{font-size:16.5px;line-height:28.8px;}
  }
  br.xsShow{display:none;}

  &.statNav{padding-top:5px;
    .left,.right{margin-top:5px;}
  }
  &:not(.statNav) .logoLink{max-width:240px;}

  .mobNav,.navItem.mob,.dropLi.mob{display:none;}
  .mobNav{float:right;}
  .mobileNavBtn{@include sec-default();width:26px;padding:0;
    span{@include sec-default();height:2px;margin:2.5px 0;background-color:$logold;}
    p,.drawerText{display:none;}
  }  



  //////////////////////////////////////
  ///////////// NEW LOGO ////////////
  /////////////////////////////////////
  // &.white .imgGold{display:none;}
  // &.gold{
    // .imgWhite{display:none;}    
    &.statNav{padding-top:0px;
      .logoLink{max-width:360px;}
      .left,.right{margin-top:30px;}
    }
    &:not(.statNav){
      .hdrWrap{padding-top:0;}
      .left,.right{margin-top:20px;}
      ul{margin-bottom:0;}
    }
    .mobileNavBtn span{background-color:$white;}
  // }
  ////////////////////////////////////




  @media (min-width:1380px){
    .left{margin-left:-3%;}
    .right{margin-right:-3%;}
  }

  @include bp(tx){
    .hdrWrap{width:98%;}
    .navItem{margin-left:12px;margin-right:12px;}
    .left,.right{min-width:350px;}
    .left{margin-left:1%;}
    .right{margin-right:1%;}

    .navItem, .navItem a, p{font-size:17px;}
    p a{font-size:15.7px;}
  }

  @media (min-width:1201px) and (max-width:1365px){.left{text-align:left;}}
  @include bp(tw){
    &::before{@include after();left:0;width:49.5%;border-right:$border;height:32px;z-index:0;}
    .hdrWrap{padding:0;}
    .navBarCustom{margin-bottom:0px;}

    .logo{display:none;}
    .logoBlk{display:block;padding-bottom:20px;}

    .left,.right{min-width:auto;width:50%;}
    .left{text-align:right;padding-right:15px;margin-left:0;}
    .right{text-align:left;padding-left:15px;margin-right:0;}

    .custDrop{top:30px !important;
      a{font-size:16px;}
    }
    p{margin-bottom:0;}

    &.statNav{padding:20px 0 3px;
      .left,.right{margin-top:0;}
    }
    &:not(.statNav){
      &::before{bottom:5px;}
      .logoBlk{display:none;}
      .left,.right{margin:5px 0 7px;}
    }



    ///////////// NEW LOGO ////////////
    &.statNav{padding:10px 0 3px;
      .left,.right{margin-top:0px;}
    }
    ///////////////////////////////////
  }

  @include bp(nn){
    .navItem, .navItem a, p{font-size:16.5px;}
    p a{font-size:15.5px;}

    .ddNav{width:90%;max-width:700px;@include center-block();}
    .custDrop{
      li:nth-child(4),li:last-child{margin-top:0;}
      a{font-size:16px;}
    }
  }
 
  @include bp(og){text-align:center;
    &::before{display:none;}
    &.statNav{padding-top:10px;}

    .hdrWrap{max-width:100%;width:100%;}
    .left,.navBarCustom{display:none;}
    
    .logoBlk{padding-bottom:10px;}
    .logoLink{max-width:280px;}
    .mobNav{display:block;position:absolute;right:50px;top:12px;}

    .right{width:100%;display:block;float:left;margin-top:0;text-align:center;}//padding:5px 0 6px;background-color:rgba($logold,.2);}
    p{font-size:16px;}
    p a{font-size:15px;}




    ///////////// NEW LOGO ////////////
    // &:not(.statNav) .logoBlk{display:inline-block;}
    // &.white{padding-top:10px;
    //   .logoLink{max-width:280px;}
    //   .right{padding:5px 0 6px;background-color:rgba($logold,.2);}
    // }

    // &.gold{
      background-color:$logold;padding:0px;
      &.statNav{padding:0px;
        .logoLink{max-width:300px;}
      }
      .logoBlk{padding-bottom:0px;}
      .mobNav{top:15px;}
      .right{padding:5px 0 6px;background-color:lighten($logold,5%);}
      p{color:$white;}
    // }
    ////////////////////////////////////
  }

  @include bp(oc){
    .logoLink{max-width:250px;}
    .mobNav{right:20px;top:10px}

    ///////////// NEW LOGO ////////////
    // &.white{
    //   .logoBlk{padding-bottom:5px;}
    //   .logoLink{max-width:250px;}
    // }
  }

  @include bp(fi){
    .logoBlk,.mobNav{display:inline-block;}
    .logoBlk{margin-right:10px;}
    .logoLink{max-width:220px;}
    .mobNav{position:relative;top:auto;right:auto;float:none;margin-left:10px;
      button{padding:0;}
      span:first-child{margin-top:0;}
    }
    
    .right{padding-bottom:5px;}
    p{font-size:15px;line-height:1.2em;}
    p a{font-size:14px;}


    ///////////// NEW LOGO ////////////
    // &.white{
    //   .logoLink{max-width:220px;}
    //   .logoBlk{padding-bottom:10px;}
    //   .mobNav{position:relative;top:auto;right:auto;float:none;margin-left:10px;
    //     button{padding:0;}
    //     span:first-child{margin-top:0;}
    //   }
    // }
    // &.gold{
      .logoLink, &.statNav .logoLink{max-width:240px;}
      .mobNav{position:relative;top:auto;right:auto;float:none;margin-left:10px;margin-top:14px;
        button{padding:0;}
        span:first-child{margin-top:0;}
      }
      .mobileNavBtn{width:22px;}
    // }
    ////////////////////////////////////
  }
  @media (max-width:399px){br.xsShow{display:block;}}
}

// .cNavDrawer{background-color:$blue;}
.SideNavList{width:95%;max-width:320px;  padding:20px 20px 10px;//padding:40px 30px 0;margin-top:50px;
  .SideNav{margin-top:10px;}
  .closeIcon {color:$black;position:absolute;right:10px;top:15px;cursor:pointer;font-weight:normal;
    svg{width:30px;height:30px;}
  }

  .navItem,.dropLi{@include sec-default();line-height:1em;margin:0 0 25px;
    &.mob{display:block;}
    &.fw{display:none;}

    &.withDrop{margin:0;
      & > a{display:none;}
    }

    a, button{font-weight:400;color:$color2;text-transform:none;font-size:17px;line-height:1em;padding:0;
      &:hover{opacity:.7;color:$color2;}
    }
  }
  .custDrop{@include sec-default();margin-top:5px;background-color:transparent;padding:0;position:relative;box-shadow:none;}

  @include bp(xx){padding:10px;}
}
.drContact{padding:20px 10px 0;border-top:$border;
  p{font-size:15px;}
  p.ap1{margin-bottom:5px;}
  span.cat{letter-spacing:2px;font-size:14px;}
  span.smText{font-style:italic;}
  svg{width:15.5px;height:15.5px;margin-top:-3px;}
}
